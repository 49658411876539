<template>
    <div class="cbox">
        <div class="headbar">
            <div style="float: right">
                <el-form :inline="true" :model="queryForm" style="">
                    <el-form-item label="名称" min-width="120">
                        <el-input size="small" v-model="queryForm.keyword" placeholder="请输入关键字"></el-input>
                    </el-form-item>




                    <el-form-item>
                        <el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>


                        <el-upload style="display: inline-block; margin-left: 10px;" action="" :multiple="true"
                            :http-request="uploadOss" :on-success="handleFileUpload" :on-progress="handleProcess"
                            :show-file-list="false" name="image">
                            <el-button size="small" type="primary" icon="el-icon-upload">上传</el-button>
                        </el-upload>

                        <el-button size="small" style="margin-left: 10px;" type="primary" icon="el-icon-folder"
                            @click="addFolder">文件夹</el-button>






                    </el-form-item>
                </el-form>

            </div>
            <div style="padding-top: 14px">
                <el-breadcrumb>
                    <el-breadcrumb-item>文件管理</el-breadcrumb-item>
                    <el-breadcrumb-item>文件列表</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="bcontent">
            <div style="height:4px">
                <div id="processbox" style="height:4px;border-radius: 2px; background-color: green; width: 0px;"></div>
            </div>
            <el-card>



                <div style="display:flex">
                    <div class="nextpath" @click="goPath(0)">根目录</div>
                    <div class="nextpath" @click="goPath(p.id)" v-for="(p,i) in pathList" :key="i"><i
                            class="el-icon-arrow-right"></i>{{p.filename}}</div>
                </div>



                 <el-table v-tableFit stripe :data="DataList" style="width: 100%;margin-bottom: 20px; margin-top: 5px; color: #000;"
                    border size="mini">
                    <el-table-column type="selection" width="55">
                    </el-table-column>
                    <el-table-column prop="cover" label="类型" width="60">
                        <template slot-scope="scope">
                            <div style="text-align:center">

                                <img v-if="scope.row.ftype=='file'" :src="'./img/icon/'+scope.row.filetype+'.png?'"
                                    class="typeicon" />
                                <img v-if="scope.row.ftype=='folder'" :src="'./img/icon/folder.png'" class="typeicon" />


                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column prop="filename" label="文件名称" min-width="230">
                        <template slot-scope="scope">
                            <div class="filenameitem">
                                <span @click.stop="viewInfo(scope.row)"> {{scope.row.filename}}</span>
                                <div class="fileicon">
                                    <i class="el-icon-download" title="下载" @click.stop="openFile(scope.row)"></i>
                                    <i v-if="teacherid==scope.row.ctch_uid" class="el-icon-delete" title="删除"
                                        @click.stop="deleteFile(scope.row)" style="margin-left:10px"></i>
                                    <i v-if="teacherid==scope.row.ctch_uid" class="el-icon-edit" title="重命名"
                                        @click.stop="renameFile(scope.row)" style="margin-left:10px"></i>
                                </div>
                            </div>

                        </template>
                    </el-table-column>

                    <el-table-column prop="id" label="上传日期" width="180">
                        <template slot-scope="scope">
                            {{scope.row.cdate}}

                        </template>
                    </el-table-column>

                    <el-table-column prop="filename" label="文件大小" width="120">
                        <template slot-scope="scope">
                            {{getSize(scope.row.filesize)}}

                        </template>
                    </el-table-column>
                    <el-table-column prop="filename" label="上传用户" width="120">
                        <template slot-scope="scope">
                            <img :src="scope.row.tch_avatar||scope.row.stu_avatar" class="headimg" />
                            <span
                                style="vertical-align:middle;line-height: 32px; margin-left: 5px; color: #888;">{{scope.row.username||scope.row.stu_name}}</span>
                        </template>
                    </el-table-column>
                </el-table>





            </el-card>
        </div>



    </div>
</template>
  
<script>

export default {
    components: {},
    data() {
        return {
            queryForm: {
                keyword: ""
            },
            pid: 0,
            teacherid: 0,
            DataList: [],
            pathList: []
        }
    },
    mounted() {
        this.$http.post("/api/get_teacher_info").then(res => {
            this.teacherid = res.data.id
            this.getList()
        })

    },
    watch: {
        $route: function (n, o) {
            console.log(n)
            let dir = n.query.dir
            if (dir) {
                dir = dir.split(',')

                let pid = dir[dir.length - 1];
                let isback =false;
                for(let i=0;i<this.pathList.length;i++){
                    if(this.pathList[i].id==pid&&i!=this.pathList.length-1){
                        console.log(i,pid,this.pathList.length)
                        this.pathList.splice(i,1)
                        isback = true
                        break
                    }
                }
                console.log(isback)
                if(isback){
                    this.pid = pid
                    this.getList()
                }
              

            }else{
                this.pathList=[]
                this.pid = 0
                this.getList()
            }


        }
    },
    methods: {
        getSize(size) {
            if (size) {


                if (size < 1024) {
                    return size + "B"
                } else if (size >= 1024 && size < 1024 * 1024) {
                    return parseInt(size / (1024) * 10) / 10 + "KB"
                } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
                    return parseInt(size / (1024 * 1024) * 10) / 10 + "MB"
                } else if (size >= 1024 * 1024 * 1024 && size < 1024 * 1024 * 1024 * 1024) {
                    return parseInt(size / (1024 * 1024 * 1024) * 10) / 10 + "GB"
                }
            } else {
                return "-"
            }
        },
        getList() {
            this.$http.post("/api/t_sch_filesstore_list", { pid: this.pid, ktype: "filename", keyword: this.queryForm.keyword }).then(res => {
                this.DataList = res.data
            })
        },
        addFolder() {
            this.$prompt('请输入文件夹名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if (value.trim()) {
                    this.$http.post("/api/t_sch_filesstore_edit",
                        {
                            pid: this.pid,
                            ftype: "folder",
                            filename: value.trim(),
                            filesize: 0,
                            filetype: '',
                            url: '',
                            is_share: 1
                        }).then(res => {
                            this.$message.success("上传成功")
                            this.getList()
                        })
                }
            }).catch(() => {

            });

        },
        search() { this.getList() },
        handleProcess(e) {

            let p = e.loaded / e.total * 100
            $("#processbox").show().css("width", p + "%")
        },
        handleFileUpload(e) {

            let filename = e.fileName
            let filetype = e.fileName.substr(e.fileName.lastIndexOf(".") + 1)
            console.log(filename, filetype)
            $("#processbox").hide()
            this.$http.post("/api/t_sch_filesstore_edit",
                {
                    pid: this.pid,
                    ftype: "file",
                    filetype: filetype,
                    filename: filename,
                    filesize: e.size,
                    url: e.src,
                    is_share: 1
                }).then(res => {
                    this.$message.success("上传成功")
                    this.getList()
                })
        },
        viewInfo(e) {

            if (e.ftype == "folder") {
                this.pid = e.id;
                this.pathList.push(e)
                this.getList()
                window.location.href = "/#" + "/files?dir=" + encodeURIComponent(this.pathList.map(a => { return a.id }).join(","))
                // this.$router.push("/files?dir="+encodeURIComponent(this.pathList.map(a=>{return a.id}).join(",")))
            } else {
                this.openFile(e)
            }
        },
        goPath(id) {
            if (id == 0) {
                this.pathList = [];

            } else {
                for (let i = 0; i < this.pathList.length; i++) {
                    if (this.pathList[i].id == id) {
                        this.pathList.splice(i + 1)

                        break
                    }
                }
            }
            this.pid = id
            this.getList()
        },
        openFile(e) {
            if (e.url) {
                window.open(e.url, "_blank")
            }
        },
        deleteFile(e) {
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/t_sch_filesstore_delete", {
                    id: e.id
                }).then(res => {
                    if (res.data.affectedRows) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    } else {
                        this.$message({
                            type: 'error',
                            message: '删除失败，没有权限!'
                        });
                    }

                })

            }).catch(() => {

            });
        },
        renameFile(e) {
            this.$prompt('请输入新的文件名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({ value }) => {
                if (value.trim()) {
                    e.filename = value.trim()
                    this.$http.post("/api/t_sch_filesstore_edit", e).then(res => {
                        this.$message.success("修改成功")
                        this.getList()
                    })
                }
            }).catch(() => {

            });
        },
    },

};
</script>
  
<style scoped>
.filerow {
    padding: 10px;
    height: 30px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    line-height: 30px;
    display: flex;
    color: #333;
}

.filerow:hover {
    background-color: aliceblue;
}


.filename {
    width: 30%;
    padding-left: 10px;
}

.filedate {
    width: 120px;
    font-size: 12px;
}

.headimg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    vertical-align: middle;
}

.typeicon {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    vertical-align: middle;
}

.filenameitem {
    position: relative;
}

.fileicon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    color: #409EFF;
    display: block;
}

.filenameitem:hover .fileicon {
    display: block;
}

.filenameitem:hover {
    color: #409EFF;
    cursor: pointer;
}

.nextpath {
    font-size: 14px;
    color: #409EFF;
    cursor: pointer;
}
</style>